import { BtnSmall } from '@/components/buttons/BtnSmall';
import { PageSection } from '@/components/page/PageSection';
import { uuid } from '@/lib/helpers/uuid';
import { createComponent, watchRun } from '@/lib/vue';
import {
  IForm,
  IFormSchema,
  IModel,
  IRequireField,
  ITelefoneModel,
} from '@/typings';
import { computed } from '@vue/composition-api';
import { FormFields } from './FormFields';

interface IJsxProps {
  // model
  value?: ITelefoneModel[];
  form: IForm<IModel>;
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (telefones: ITelefoneModel[]) => void;
}

export const TelefonesFields = createComponent<IJsxProps, IEvents>({
  name: 'TelefonesFields',
  props: {
    value: { type: Array, required: true },
    form: { type: Object, required: true },
  },
  setup(props: IProps, ctx) {
    function emitInput(value: ITelefoneModel[]) {
      ctx.emit('input', value);
    }

    function handleAdd() {
      emitInput([...props.value, { id: uuid(), numero: null }]);
    }

    watchRun(
      () => props.value.length,
      length => length === 0 && handleAdd(),
    );

    return () => (
      <PageSection divider title="Telefones">
        <div class="flex flex-col" slot="fields">
          {props.value.map((telefone, idx) => (
            <TelefoneField
              key={telefone.id}
              telefones={props.value}
              value={telefone}
              idx={idx}
              form={props.form}
              handleAdd={handleAdd}
              emitInput={emitInput}
            />
          ))}
        </div>
      </PageSection>
    );
  },
});

interface IFieldProps {
  telefones: ITelefoneModel[];
  value: ITelefoneModel;
  idx: number;
  form: IForm<any>;
  handleAdd: () => void;
  emitInput: (telefones: ITelefoneModel[]) => void;
}

const TelefoneField = createComponent<IFieldProps>({
  name: 'TelefoneField',
  props: {
    value: { type: Object, required: true },
    telefones: { type: Array, required: true },
    idx: { type: Number, required: true },
    form: { type: Object, required: true },
    handleAdd: { type: Function, required: true },
    emitInput: { type: Function, required: true },
  },
  setup(props, ctx) {
    const $showRemoveBtn = computed(() => props.telefones.length > 1);

    const $showAddBtn = computed(
      () =>
        props.idx + 1 === props.telefones.length &&
        !!props.telefones[props.idx].numero,
    );

    const schema: IFormSchema<ITelefoneModel> = {
      numero: {
        label: 'Telefone',
        type: 'text',
        mask: 'telefone',
      },
    };

    function handleRemove() {
      props.emitInput(props.telefones.filter(v => v.id !== props.value.id));
    }

    function handleInput() {
      props.telefones[props.idx] = props.value;

      props.emitInput(props.telefones);
    }

    return () => {
      return (
        <div class="flex items-center">
          <FormFields
            form={props.form}
            schema={schema}
            value={props.telefones[props.idx]}
            onInput={handleInput}
            style={{
              maxWidth: '200px',
            }}
            class="telefone-field"
          />

          <div class="mb-6">
            {$showRemoveBtn.value && (
              <BtnSmall
                remove
                tooltip="Remover telefone"
                onClick={handleRemove}
              />
            )}

            {$showAddBtn.value && (
              <BtnSmall
                add
                tooltip="Adicionar telefone"
                onClick={props.handleAdd}
              />
            )}
          </div>
        </div>
      );
    };
  },
});
