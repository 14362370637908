import { EnderecoFields } from '@/components/form/fields/EnderecoFields';
import { FormFields } from '@/components/form/fields/FormFields';
import { TelefonesFields } from '@/components/form/fields/TelefonesFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { INomeEvents, useNome } from '@/lib/composables/utils/useNome';
import { uuid } from '@/lib/helpers/uuid';
import { ContatoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { redirectError } from '@/routes/utils';
import {
  IContatoFormModel,
  IContatoFormSchema,
  ITelefoneModel,
} from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<IContatoFormModel>, INomeEvents {}

export const ContatoForm = createComponent<IProps, IEvents>({
  name: 'ContatoForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const indexRoute = Routes.app.contatos.index;

    const { $form, $schema, emitDelete, emitSubmit } = useForm(props, ctx);

    useNome({
      ctx,
      watchNome: () => $form.value.model.geral.nome,
    });

    return () => (
      <MyForm
        form={$form.value}
        cancelTo={indexRoute}
        onSubmit={emitSubmit}
        onDelete={emitDelete}
      >
        <PageSection title="Geral">
          <FormFields
            slot="fields"
            form={$form.value}
            schema={$schema.value.geral}
            v-model={$form.value.model.geral}
          />
        </PageSection>

        <TelefonesFields
          form={$form.value}
          v-model={$form.value.model.telefones}
        />

        <EnderecoFields
          form={$form.value}
          v-model={$form.value.model.endereco}
        />

        <PageSection title="Outras informações">
          <FormFields
            slot="fields"
            form={$form.value}
            schema={$schema.value.outrasInformacoes}
            v-model={$form.value.model.outrasInformacoes}
          />
        </PageSection>
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  return useFormConfig<IContatoFormModel, IContatoFormSchema>({
    page: props.page,
    ctx,
    initialValue: {
      geral: {
        nome: null,
        email: null,
      },
      telefones: [],
      endereco: {
        enderecoCep: null,
        enderecoLogradouro: null,
        enderecoNumero: null,
        enderecoComplemento: null,
        enderecoBairro: null,
        enderecoCidade: null,
        enderecoUf: null,
        enderecoPais: 'Brasil',
      },
      outrasInformacoes: {
        observacao: null,
      },
    },
    mapSchema: () => ({
      geral: {
        nome: {
          label: 'Nome',
          type: 'text',
          validations: {
            required: true,
          },
        },
        email: {
          label: 'Email',
          type: 'email',
        },
      },
      outrasInformacoes: {
        observacao: {
          label: 'Observação',
          type: 'textarea',
        },
      },
    }),
    async loadEditCallback({ id, setFormModel }) {
      const editValue = await ContatoService.getById(id);

      if (!editValue) return redirectError(404);

      const telefones: ITelefoneModel[] = (editValue.telefones || []).map(
        numero => ({
          id: uuid(),
          numero,
        }),
      );
      setFormModel({
        geral: {
          nome: editValue.nome,
          email: editValue.email,
        },
        telefones,
        endereco: {
          enderecoCep: editValue.enderecoCep,
          enderecoLogradouro: editValue.enderecoLogradouro,
          enderecoNumero: editValue.enderecoNumero,
          enderecoComplemento: editValue.enderecoComplemento,
          enderecoBairro: editValue.enderecoBairro,
          enderecoCidade: editValue.enderecoCidade,
          enderecoUf: editValue.enderecoUf,
          enderecoPais: editValue.enderecoPais,
        },
        outrasInformacoes: {
          observacao: editValue.observacao,
        },
      });
    },
  });
}
