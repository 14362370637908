import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { MyPage } from '@/components/page/MyPage';
import { QueryGraphql } from '@/graphql/query';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { formatTelefone } from '@/lib/form';
import { ContatoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import {
  IContatoItem,
  IContatosQuery,
  IContatosQueryVariables,
  IDataTableHeader,
  IIndexDataTableActions,
  IIndexDataTableButton,
} from '@/typings';

export default createComponent({
  name: 'ContatosPage',
  setup(props, ctx) {
    const btn: IIndexDataTableButton = {
      text: 'Novo Contato',
      to: Routes.app.contatos.new,
    };

    const actions: IIndexDataTableActions = {
      editRoute: Routes.app.contatos.edit,
      removeMany: ContatoService.deleteMany,
    };

    const headers: IDataTableHeader<IContatoItem>[] = [
      {
        text: 'Nome',
        value: 'nome',
        mapValue: v => v.nome,
      },
      {
        text: 'Telefone',
        value: 'telefone',
        mapValue: v => v.telefone,
      },
      {
        text: 'Email',
        value: 'email',
        mapValue: v => v.email,
      },
    ];

    const { $data, $loading } = useContatosQuery();

    return () => (
      <MyPage title="Contatos">
        <IndexDataTable
          btn={btn}
          headers={headers}
          items={$data.value}
          loading={$loading.value}
          actions={actions}
        />
      </MyPage>
    );
  },
});

function useContatosQuery() {
  return useQueryConfig<
    IContatosQuery,
    IContatoItem[],
    IContatosQueryVariables
  >({
    query: QueryGraphql.ContatosQuery,
    variables: () => ({}),
    mapData: result =>
      result?.contatos.nodes.map(contato => ({
        id: contato.id,
        nome: contato.nome,
        telefone: formatTelefone(contato.telefones?.[0]),
        email: contato.email,
      })) || [],
  });
}
